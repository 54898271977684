import styled from "@emotion/styled";
import { CircularProgress } from "@ibeckinc/ui-elements";
import Image from "next/image";
import React, { FunctionComponent } from "react";

import { NextLink } from "../NextLink";
import { useGetBanners } from "./query";

type Props = {
  className?: string;
  bannerKey: string;
  path: string;
  currentTime: string;
  orientation?: "horizontal" | "vertical";
  slideIn?: boolean;
};

export const BannerList: FunctionComponent<Props> = ({
  className,
  bannerKey,
  path,
  currentTime,
  orientation = "vertical",
}) => {
  const { data: banners } = useGetBanners({
    key: bannerKey,
    path,
    currentTime,
  });

  return (
    <Root className={className}>
      <React.Suspense fallback={<CircularProgress />}>
        {banners && (
          <BannerContainer orientation={orientation}>
            {banners.map(({ name, image, link, width, height }, idx) => (
              <NextLink key={`banner-${bannerKey}-${idx}`} href={link}>
                <Image src={image} alt={name} width={width} height={height} />
              </NextLink>
            ))}
          </BannerContainer>
        )}
      </React.Suspense>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const BannerContainer = styled.div<{ orientation: "horizontal" | "vertical" }>`
  display: flex;
  flex-direction: ${(props) =>
    props.orientation === "horizontal" ? "row" : "column"};
  flex-wrap: wrap;
  margin-top: 36px;
  gap: 16px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
