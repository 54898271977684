import { z } from "zod";

import { PublicStatusResponse } from "./common";
import { TagResponse } from "./tag";

export const CompanyProfileResponse = z.object({
  id: z.string(),
  name: z.string(),
  foundedAt: z.string(),
  industry: z.string(),
  businessScope: z.string(),
  headquarter: z.string(),
  capital: z.string(),
  president: z.string(),
  presidentProfile: z.string(),
  employeesAmount: z.string(),
  sales: z.string(),
  groupCompanies: z.string(),
  heading: z.string(),
  caption: z.string(),
  description: z.string(),
  publicStatus: PublicStatusResponse,
  siteUrl: z.string(),
  logo: z.string(),
  thumbnail: z.string(),
  headerImage: z.string(),
  updatedAt: z.string().optional(),
  tags: z.array(TagResponse).optional(),
  urlKeyword: z.string().nullable(),
});
export type CompanyProfile = z.infer<typeof CompanyProfileResponse>;

export const RecruitmentResponse = z.object({
  title: z.string().nullable(),
  heading: z.string(),
  description: z.string(),
  employment: z.string(),
  occupation: z.string(),
  qualification: z.string(),
  recruitUrl: z.string(),
  entryDeadline: z.string(),
  screening: z.string(),
});

export const EmployeeResponse = z.object({
  columnId: z.string(),
  columnUrlKeyword: z.string().nullable(),
  firstName: z.string(),
  lastName: z.string(),
  romanFirstName: z.string(),
  romanLastName: z.string(),
  university: z.string(),
  position: z.string(),
  history: z.string(),
  careerBackground: z.string(),
  jobDescription: z.string(),
  hardship: z.string(),
  requirement: z.string(),
  advice: z.string(),
  portraitUrl: z.string(),
});

export const CompanyResponse = z.object({
  company: CompanyProfileResponse,
  recruitments: z.array(RecruitmentResponse),
  employees: z.array(EmployeeResponse),
});
export type Company = z.infer<typeof CompanyResponse>;

export const CompaniesResponse = z.array(CompanyResponse);

export const CompanyEntryOptionReponse = z.object({
  id: z.string(),
  name: z.string(),
  deadlineAt: z.string(),
});
export type CompanyEntryOption = z.infer<typeof CompanyEntryOptionReponse>;

export const CompanyEntryReponse = z.object({
  id: z.string(),
  name: z.string(),
  multiple: z.boolean(),
  options: z.array(CompanyEntryOptionReponse),
});
export type CompanyEntry = z.infer<typeof CompanyEntryReponse>;
export const CompanyEntriesReponse = z.array(CompanyEntryReponse);
