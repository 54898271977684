import { ICompany } from "./Company";
import { ITag } from "./Tag";

export interface ISeminarProgramResponse {
  id: number;
  seminarId: number;
  seminarStatus: SeminarStatus;
  graduation: number;
  capacity: number;
  maxRate: number;
  minRate: number;
  placeId: number;
  startAt: string;
  endAt: string;
  deadLine: string;
}

export interface IStudentProgram {
  id: string;
  seminarId: string;
  category: string;
  title: string;
  thumbnail: string;
  topImage: string;
  startAt: string;
  endAt: string;
  tags: ITag[];
}

export interface ISeminarProgram {
  id: string;
  category: string;
  capacity: number;
  conductorId: string;
  contactAddress: string;
  graduation: string;
  maxRate: number;
  minRate: number;
  placeId: string;
  remark: string;
  seminarId: string;
  status: SeminarStatus;
  startAt: string;
  endAt: string;
  deadline: string;
  title: string;
  surveyUrl: string | null;
  acitons: string[];
  place: IPlace | null;
}

export interface ISeminarDescription {
  id: string;
  seminarId: string;
  places: IPlace[];
  presenters: IPresenter[];
  sections: ISeminarDiscriptionSection[];
}

export interface ISeminarDiscriptionSection {
  id: string;
  title: string;
  subTitle?: string;
  body: string;
}

export interface IPlace {
  id: string;
  name: string;
  address: string;
  access: string;
  capacity: number;
}

export interface IPresenter {
  name: string;
  image: string;
  company: string;
  introduction?: string;
  position: string;
  message?: string;
  tags?: ITag[];
}

export interface ISeminar {
  id: string;
  capacity: number;
  category: SeminarCategory;
  conductorId: string;
  descriptionId: string;
  graduation: string;
  maxRate: number;
  minRate: number;
  headerImage: string;
  programIds: string[] | null;
  requiredMentor: number;
  tags: ITag[];
  thumbnail: string;
  title: string;
  description: ISeminarDescription;
  places: IPlace[];
  programs: ISeminarProgram[];
  company: ICompany | null;
  urlKeyword: string | null;
}

export const SEMINAR_ACTION = {
  mentorwanted: "mentorwanted",
  editable: "editable",
  restricteditable: "restricteditable",
  select: "select",
  contact: "contact",
  confirm: "confirm",
  change: "change",
  evaluate: "evaluate",
  survey: "survey",
  aggrigate: "aggrigate",
  check: "check",
} as const;
export type SeminarAction =
  (typeof SEMINAR_ACTION)[keyof typeof SEMINAR_ACTION];

export const GRADUATION_TARGET = {
  2021: "2021",
  2022: "2022",
  2023: "2023",
} as const;
export type GraduationTarget =
  (typeof GRADUATION_TARGET)[keyof typeof GRADUATION_TARGET];

export const ONLINE_TYPE = {
  online: "online",
  offline: "offline",
  dependson: "dependson",
} as const;
export type OnlineType = (typeof ONLINE_TYPE)[keyof typeof ONLINE_TYPE];

export const SEMINAR_STATUS = {
  planned: "planned",
  determined: "determined",
  fixed: "fixed",
  holding: "holding",
  done: "done",
  surveying: "surveying",
  closed: "closed",
  aborted: "aborted",
} as const;
export type SeminarStatus =
  (typeof SEMINAR_STATUS)[keyof typeof SEMINAR_STATUS];

export const SEMINAR_CATEGORY = {
  selection: "selection",
  lecture: "lecture",
  sponsored: "sponsored",
} as const;
export type SeminarCategory =
  (typeof SEMINAR_CATEGORY)[keyof typeof SEMINAR_CATEGORY];

export const SEMINAR_CATEGORY_LABEL: { [key in SeminarCategory]: string } = {
  lecture: "講座",
  sponsored: "共催イベント",
  selection: "説明選考会",
};
