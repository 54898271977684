import styled from "@emotion/styled";
import { Border, Typography } from "@ibeckinc/ui-elements";
import Image from "next/image";
import React, { FunctionComponent, useMemo } from "react";

import { truncate } from "../../utils";
import SeminarCardBase, { SeminarCardBaseProps } from "./Base";

const { Text } = Typography;

const LOGO_SIZE = 48;

type Props = Omit<SeminarCardBaseProps, "height" | "variant" | "labelType"> & {
  companyName: string;
  logo?: string;
  urlKeyword: string | null;
};

const CompanySeminarCard: FunctionComponent<Props> = ({
  className,
  companyName,
  datetime,
  highlightTagIds,
  id,
  logo,
  tags,
  thumbnail,
  title,
  urlKeyword,
}) => {
  const truncatetedTitle = useMemo(() => title && truncate(title, 64), [title]);

  return (
    <SeminarCardBase
      className={className}
      datetime={datetime}
      footer={
        <Footer>
          {logo && (
            <LogoWrapper>
              <Image
                src={logo}
                alt={companyName}
                width={LOGO_SIZE}
                height={LOGO_SIZE}
                priority
              />
            </LogoWrapper>
          )}
          <Text variant="caption">{companyName}</Text>
        </Footer>
      }
      highlightTagIds={highlightTagIds}
      id={id}
      tags={tags}
      thumbnail={thumbnail}
      title={truncatetedTitle}
      seminarCategory="sponsored"
      urlKeyword={urlKeyword}
      variant="vertical"
    />
  );
};

const Footer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

const LogoWrapper = styled.div`
  width: ${LOGO_SIZE}px;
  height: ${LOGO_SIZE}px;
  border-radius: 50%;
  overflow: hidden;
  border: solid 1px ${Border.Base};
`;

export default CompanySeminarCard;
