import { z } from "zod";

import { PublicStatusResponse } from "./common";
import { CompanyResponse } from "./company";
import { TagResponse } from "./tag";

export const SeminarPlaceResponse = z.object({
  id: z.string(),
  name: z.string(),
  address: z.string(),
  access: z.string(),
  capacity: z.number(),
  contactAddress: z.string().optional(),
  remark: z.string().optional(),
});
export type SeminarPlace = z.infer<typeof SeminarPlaceResponse>;

const SeminarProgramResponse = z.object({
  id: z.string(),
  category: z.string(),
  endAt: z.string(),
  placeId: z.string(),
  seminarId: z.string(),
  seminarUrlKeyword: z.string().nullable(),
  status: z.string().optional(),
  startAt: z.string(),
  deadlineAt: z.string().nullable(),
  surveyUrl: z.string().nullable(),
  tags: z.array(TagResponse),
  thumbnail: z.string(),
  title: z.string(),
});
export type SeminarProgram = z.infer<typeof SeminarProgramResponse>;

export const SeminarProgramsResponse = z.array(SeminarProgramResponse);

export const SeminarDescriptionResponse = z.object({
  id: z.string(),
  seminarId: z.string(),
  places: z.array(SeminarPlaceResponse),
  presenters: z.array(
    z.object({
      name: z.string(),
      image: z.string(),
      company: z.string(),
      position: z.string(),
      introduction: z.string(),
      message: z.string(),
    }),
  ),
  sections: z.array(
    z.object({
      id: z.string(),
      title: z.string(),
      subTitle: z.string(),
      body: z.string(),
    }),
  ),
});
export type SeminarDescription = z.infer<typeof SeminarDescriptionResponse>;

export const SeminarResponse = z.object({
  id: z.string(),
  category: z.string(),
  descriptionId: z.string(),
  graduation: z.string(),
  headerImage: z.string(),
  programIds: z.array(z.string()).nullable(),
  tags: z.array(TagResponse),
  thumbnail: z.string(),
  title: z.string(),
  description: SeminarDescriptionResponse,
  places: z.array(SeminarPlaceResponse),
  programs: z.array(SeminarProgramResponse),
  company: CompanyResponse.nullable(),
  publicStatus: PublicStatusResponse,
  additionalFormUrl: z.string().nullable(),
  prerequisiteSeminarId: z.string().nullable(),
  urlKeyword: z.string().nullable(),
});
export type Seminar = z.infer<typeof SeminarResponse>;
export const SeminarsResponse = z.array(SeminarResponse);

export const SeminarResultResponse = z.object({
  data: z.array(SeminarResponse),
  total: z.number(),
});
export type SeminarResult = z.infer<typeof SeminarResultResponse>;

export const SeminarProgramByCompanyResponse = z.object({
  company: z.object({
    id: z.string(),
    name: z.string(),
    logo: z.string(),
  }),
  programs: z.array(SeminarProgramResponse),
});
export type SeminarProgramByCompany = z.infer<
  typeof SeminarProgramByCompanyResponse
>;

export const CompanySeminarProgramResultResponse = z.object({
  data: z.array(SeminarProgramByCompanyResponse),
  total: z.number(),
});
export type CompanySeminarProgramResult = z.infer<
  typeof CompanySeminarProgramResultResponse
>;

export const SeminarProgramResultResponse = z.object({
  data: z.array(SeminarProgramResponse),
  total: z.number(),
});
export type SeminarProgramResult = z.infer<typeof SeminarProgramResultResponse>;

export const SeminarProgramByDateResponse = z.object({
  date: z.string(),
  programs: z.array(SeminarProgramResponse),
});
export type SeminarProgramByDate = z.infer<typeof SeminarProgramByDateResponse>;

export const SeminarProgramByDateResultResponse = z.object({
  data: z.array(SeminarProgramByDateResponse),
  total: z.number(),
});
export type SeminarProgramByDateResult = z.infer<
  typeof SeminarProgramByDateResultResponse
>;
