import useSWR from "swr";

import { getFetcher, useFetcher } from "../../hooks/useFetcher";
import { Banner, BannersResponse } from "../../types/banner";
import { decode, url } from "../../utils";

export const GetBannersKey = "/banners/v2";

export const useGetBanners = ({
  key,
  path,
  currentTime,
}: {
  key: string; // key must be either A1, B1, C1, S1
  path: string; // pagePath
  currentTime?: string;
}) => {
  console.log(key, path, currentTime);
  const pathname = url(GetBannersKey, {
    key,
    path,
    currentTime: currentTime ?? new Date().toISOString(),
  });
  console.log(pathname);
  return useSWR<Banner[]>(
    pathname,
    useFetcher<Banner[]>(
      undefined,
      ({ data }) => decode(BannersResponse.safeParse(data)).data,
    ),
  );
};

export async function getBanners({
  key,
  path,
  currentTime,
}: {
  key: string; // key must be either A1, B1, C1, S1
  path: string; // pagePath
  currentTime?: string;
}): Promise<Banner[]> {
  const fetcher = getFetcher<Banner[]>(
    undefined,
    ({ data }) => decode(BannersResponse.safeParse(data)).data,
  );
  const pathname = url(GetBannersKey, {
    key,
    path,
    currentTime: currentTime ?? new Date().toISOString(),
  });
  return fetcher(pathname);
}
