import { z } from "zod";

export const BannerResponse = z.object({
  id: z.string(),
  name: z.string(),
  image: z.string(),
  link: z.string(),
  width: z.number(),
  height: z.number(),
});
export const BannersResponse = z.object({
  data: z.array(BannerResponse),
});
export type Banner = z.infer<typeof BannerResponse>;
