import { CardDirection } from "@ibeckinc/ui-elements";
import React, { FunctionComponent, useMemo } from "react";

import { truncate } from "../../utils";
import SeminarCardBase, { SeminarCardBaseProps } from "./Base";

type Props = Omit<SeminarCardBaseProps, "logo" | "companyName" | "variant"> & {
  actions?: React.ReactNode;
  variant?: CardDirection;
  onClick?: () => void;
};

const SeminarCard: FunctionComponent<Props> = ({
  actions,
  cardClassName,
  className,
  datetime,
  footer,
  height,
  highlightTagIds,
  id,
  seminarCategory,
  tags,
  thumbnail,
  title,
  urlKeyword,
  variant = "horizontal",
  onClick,
}) => {
  const truncatetedTitle = useMemo(() => title && truncate(title, 64), [title]);

  return (
    <SeminarCardBase
      actions={actions}
      className={className}
      cardClassName={cardClassName}
      datetime={datetime}
      footer={footer}
      height={height}
      highlightTagIds={highlightTagIds}
      id={id}
      seminarCategory={seminarCategory}
      variant={variant}
      title={truncatetedTitle}
      tags={tags}
      thumbnail={thumbnail}
      urlKeyword={urlKeyword}
      onClick={onClick}
    />
  );
};

export default SeminarCard;
