import styled from "@emotion/styled";
import {
  Card,
  CardDirection,
  CardSize,
  Chip,
  ChipVariant,
  Typography,
} from "@ibeckinc/ui-elements";
import Image from "next/image";
import React, { FunctionComponent } from "react";

import { pagesPath } from "../../lib/$path";
import {
  SEMINAR_CATEGORY_LABEL,
  SeminarCategory,
} from "../../lib/interfaces/Seminar";
import { ITag } from "../../lib/interfaces/Tag";
import { NextLink } from "../NextLink";

const { Title, Text } = Typography;

const SeminarTypes: {
  [key in SeminarCategory]: { name: string; color: ChipVariant };
} = {
  lecture: { name: SEMINAR_CATEGORY_LABEL.lecture, color: "green" },
  sponsored: { name: SEMINAR_CATEGORY_LABEL.sponsored, color: "red" },
  selection: { name: SEMINAR_CATEGORY_LABEL.selection, color: "blue" },
};

export type SeminarCardBaseProps = {
  actions?: React.ReactNode;
  cardClassName?: string;
  className?: string;
  footer?: React.ReactNode;
  height?: string;
  id: string;
  title: string;
  tags: ITag[];
  highlightTagIds?: string[];
  seminarCategory?: SeminarCategory;
  datetime?: string;
  thumbnail: string;
  urlKeyword: string | null;
  variant: CardDirection;
  onClick?: () => void;
};

const Base: FunctionComponent<SeminarCardBaseProps> = ({
  actions,
  className,
  cardClassName,
  footer,
  height,
  id,
  title,
  tags,
  highlightTagIds,
  seminarCategory,
  datetime,
  thumbnail,
  urlKeyword,
  variant,
  onClick,
}) => {
  const cardRenderer = () => (
    <_Card
      className={cardClassName}
      direction={variant}
      _height={height}
      _variant={variant}
      withFooter={footer !== undefined}
      image={
        <ImageWrapper>
          <_Image
            src={thumbnail}
            alt={title}
            width={CardSize.medium.width}
            height={CardSize.medium.height}
            priority
          />
        </ImageWrapper>
      }
      footer={footer}
    >
      <Content>
        <TopSection>
          {datetime && <Text>{datetime}</Text>}
          {seminarCategory && (
            <ChipWrapper>
              <Chip
                label={SeminarTypes[seminarCategory].name}
                variant={SeminarTypes[seminarCategory].color}
              />
            </ChipWrapper>
          )}
        </TopSection>
        <Section>
          <Title level={4} bold="bold" multiline>
            {title}
          </Title>
        </Section>
        <Section>
          {tags &&
            tags.map((tag) => (
              <Chip
                key={tag.id}
                label={tag.name}
                variant={
                  highlightTagIds?.includes(tag.id) ? "highlight" : "default"
                }
              />
            ))}
        </Section>
        {actions && <ActionSection>{actions}</ActionSection>}
      </Content>
    </_Card>
  );

  return onClick ? (
    <CardWrapper className={className} onClick={onClick}>
      {cardRenderer()}
    </CardWrapper>
  ) : (
    <Root
      className={className}
      href={pagesPath.seminars._id(encodeURIComponent(urlKeyword ?? id)).$url()}
    >
      {cardRenderer()}
    </Root>
  );
};

const CardWrapper = styled.div`
  cursor: pointer;
`;

const Root = styled(NextLink)`
  display: block;
  text-decoration: none;
`;

const _Card = styled(Card)<{
  _height?: string;
  _variant: CardDirection;
  withFooter: boolean;
}>`
  overflow: hidden;
  width: ${({ _variant }) =>
    _variant === "horizontal" ? "680px" : CardSize.medium.width};
  height: ${({ _height, _variant }) =>
    _height
      ? _height
      : _variant === "horizontal"
        ? CardSize.medium.height
        : "100%"};
  min-height: ${({ _height, _variant, withFooter }) =>
    _height
      ? _height
      : _variant === "horizontal"
        ? "none"
        : withFooter
          ? "493px"
          : "428px"};
`;

const ImageWrapper = styled.div`
  height: ${CardSize.medium.height}px;
  min-height: ${CardSize.medium.height}px;
  max-height: ${CardSize.medium.height}px;
  width: ${CardSize.medium.width}px;
  min-width: ${CardSize.medium.width}px;
  overflow: hidden;
`;

const _Image = styled(Image)`
  object-fit: cover;
  object-position: center top;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ChipWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
`;

const Section = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

const ActionSection = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  gap: 8px;
`;

export default Base;
