import { z } from "zod";

export const TagResponse = z.object({
  id: z.string(),
  name: z.string(),
});
export const TagsResponse = z.array(TagResponse);
export type Tag = z.infer<typeof TagResponse>;

export const TagCategoryResponse = z.object({
  id: z.string(),
  name: z.string(),
  tags: z.array(TagResponse),
});
export const TagCategoriesResponse = z.array(TagCategoryResponse);
export type TagCategory = z.infer<typeof TagCategoryResponse>;
