import styled from "@emotion/styled";
import { CircularProgress } from "@ibeckinc/ui-elements";
import React, { FunctionComponent } from "react";

import { SeminarCategory } from "../../lib/interfaces/Seminar";
import { device } from "../../utils";
import Heading from "../Heading";
import { SeminarCard } from "../SeminarCard";
import { useGetFeaturedSeminarsByCategory } from "./query";

type Props = {
  className?: string;
  category: string;
  title?: string;
};

export const SeminarList: FunctionComponent<Props> = ({
  className,
  category,
  title,
}) => {
  const { data } = useGetFeaturedSeminarsByCategory({ category });

  return (
    <Root className={className}>
      {title && data && <Heading.H2>{title}</Heading.H2>}
      <React.Suspense fallback={<CircularProgress />}>
        {data && (
          <CardContainer>
            {data.map(
              ({ category, id, tags, thumbnail, title, urlKeyword }) => (
                <SeminarCard
                  key={id}
                  id={id}
                  seminarCategory={category as SeminarCategory}
                  tags={tags}
                  thumbnail={thumbnail}
                  title={title}
                  variant="vertical"
                  urlKeyword={urlKeyword}
                />
              ),
            )}
          </CardContainer>
        )}
      </React.Suspense>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  gap: 48px 32px;
  width: 100%;

  @media ${device.mobile} {
    justify-content: center;
  }
`;
