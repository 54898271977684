import styled from "@emotion/styled";
import { CircularProgress } from "@ibeckinc/ui-elements";
import Image from "next/image";
import React, { FunctionComponent } from "react";
import { Slide } from "react-slideshow-image";

import { NextLink } from "../NextLink";
import { useGetBanners } from "./query";

type Props = {
  className?: string;
  bannerKey: string;
  path: string;
  currentTime: string;
  slideIn?: boolean;
};

export const BannerSlideshow: FunctionComponent<Props> = ({
  className,
  bannerKey,
  path,
  currentTime,
}) => {
  const { data: banners } = useGetBanners({
    key: bannerKey,
    path,
    currentTime,
  });

  return (
    <Root className={className}>
      <React.Suspense fallback={<CircularProgress />}>
        <Slide arrows={false} autoplay infinite>
          {banners &&
            banners.map(({ name, image, link, width, height }, idx) => (
              <div key={`banner-${bannerKey}-${idx}`}>
                <NextLink href={link}>
                  <Image src={image} alt={name} width={width} height={height} />
                </NextLink>
              </div>
            ))}
        </Slide>
      </React.Suspense>
    </Root>
  );
};

const Root = styled.div`
  width: 100%;
`;
