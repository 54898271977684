import useSWR from "swr";

import { getFetcher, useFetcher } from "../../hooks/useFetcher";
import {
  Seminar,
  SeminarProgramByDateResult,
  SeminarProgramByDateResultResponse,
  SeminarProgramResult,
  SeminarProgramResultResponse,
  SeminarsResponse,
} from "../../types/seminar";
import { decode, url } from "../../utils";

export const GetFeaturedSeminarsByCategoryKey = (category: string) =>
  url("/seminars/v2/featured", { category });

export const useGetFeaturedSeminarsByCategory = ({
  category,
}: {
  category: string;
}) => {
  const path = GetFeaturedSeminarsByCategoryKey(category);
  return useSWR<Seminar[]>(
    path,
    useFetcher(undefined, ({ data }) =>
      decode(SeminarsResponse.safeParse(data)),
    ),
  );
};

export async function getFeaturedSeminarsByCategory({
  category,
}: {
  category: string;
}): Promise<Seminar[]> {
  const fetcher = getFetcher<Seminar[]>(undefined, ({ data }) =>
    decode(SeminarsResponse.safeParse(data)),
  );
  return fetcher(GetFeaturedSeminarsByCategoryKey(category));
}

export const GetSeminarProgramsByCategoryKey = (
  category?: string,
  limit?: number,
  page?: number,
) => url("/seminars/v2/programs", { category, limit, page });

export const useGetSeminarProgramsByCategory = ({
  category,
  limit,
  page,
}: {
  category?: string;
  limit?: number;
  page?: number;
}) => {
  const path = GetSeminarProgramsByCategoryKey(category, limit, page);
  return useSWR<SeminarProgramResult>(
    path,
    useFetcher(undefined, ({ data }) =>
      decode(SeminarProgramResultResponse.safeParse(data)),
    ),
  );
};

export async function getSeminarProgramsByCategory({
  category,
  limit,
  page,
}: {
  category?: string;
  limit?: number;
  page?: number;
}): Promise<SeminarProgramResult> {
  const fetcher = getFetcher<SeminarProgramResult>(undefined, ({ data }) =>
    decode(SeminarProgramResultResponse.safeParse(data)),
  );
  return fetcher(GetSeminarProgramsByCategoryKey(category, limit, page));
}

export const GetSeminarProgramsByDateKey = "/seminars/v2/programs";

export const useGetSeminarProgramsByDate = ({
  categories,
  page,
  tagIds,
}: {
  categories?: string[];
  page?: number;
  tagIds?: string[];
}) => {
  const path = url(GetSeminarProgramsByDateKey, {
    categories,
    page,
    tagIds,
  });
  return useSWR<SeminarProgramByDateResult>(
    path,
    useFetcher(undefined, ({ data }) =>
      decode(SeminarProgramByDateResultResponse.safeParse(data)),
    ),
  );
};

export async function getSeminarProgramsByDate({
  categories,
  page,
  tagIds,
}: {
  categories?: string[];
  page?: number;
  tagIds?: string[];
}): Promise<SeminarProgramByDateResult> {
  const fetcher = getFetcher<SeminarProgramByDateResult>(
    undefined,
    ({ data }) => decode(SeminarProgramByDateResultResponse.safeParse(data)),
  );
  const path = url(GetSeminarProgramsByDateKey, {
    categories,
    page,
    tagIds,
  });
  return fetcher(path);
}
